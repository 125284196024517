import { User } from '@livv/models';
import { UserPrivileges } from '@models/users';

export enum UserNotConnectedPropertiesName {
    IS_CONNECTED = 'is_connected',
}

export enum UserConnectedPropertiesName {
    IS_ADMIN = 'is_admin',
    IS_INTERNAL = 'is_internal',
    IS_CONNECTED = 'is_connected',
    IS_PAID = 'is_paid',
    IS_TRIAL = 'is_trial',
    EXPIRED_TRIAL = 'expired_trial',
}

type UserConnectedProperties = Record<UserConnectedPropertiesName, boolean>;
type UserNotConnectedProperties = Record<UserNotConnectedPropertiesName, boolean>;
export type UserProperties = UserConnectedProperties | UserNotConnectedProperties;

const INTERNAL_EMAIL_DOMAIN = 'vogel-vogel.com';

export const processUserNotConnectedProperties = (): UserNotConnectedProperties => {
    return {
        [UserConnectedPropertiesName.IS_CONNECTED]: false,
    };
};

export const processUserConnectedProperties = (
    userInfo: User,
    userPrivileges: UserPrivileges,
): UserConnectedProperties => {
    const { email } = userInfo;
    const { isPaidCustomer, role, trialEndDate } = userPrivileges;

    return {
        [UserConnectedPropertiesName.IS_CONNECTED]: true,
        [UserConnectedPropertiesName.IS_ADMIN]: role === 'admin',
        [UserConnectedPropertiesName.IS_PAID]: isPaidCustomer,
        [UserConnectedPropertiesName.IS_INTERNAL]:
            role === 'user' && email.endsWith(INTERNAL_EMAIL_DOMAIN),
        [UserConnectedPropertiesName.EXPIRED_TRIAL]: trialEndDate
            ? trialEndDate > Date.now()
            : false,
        [UserConnectedPropertiesName.IS_TRIAL]: !isPaidCustomer,
    };
};
