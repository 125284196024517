import { getCookieValue } from '@config/axeptio';
import { getEnvVar } from '@config/configEnv';
import { TRACKING_COOKIE_AMPLITUDE, TRACKING_COOKIE_NAME } from '@utils/consts/cookie';
import { EventProps } from '@utils/types/amplitude/events';
import { UserProperties } from '@utils/types/amplitude/userProperties';

let allowAmplitudeTracking = false;

// Amplitude relies on the window object and should only be run in the browser (not server side)
const isBrowser = typeof window !== 'undefined';
const AMPLITUDE_API_KEY = getEnvVar('AMPLITUDE_API_KEY');

export const launchAmplitude = async (isLoggedUser: boolean): Promise<void> => {
    // Automatic allow tracking on sign up, after CGU acceptation
    allowAmplitudeTracking = isLoggedUser
        ? true
        : isBrowser && getCookieValue(TRACKING_COOKIE_NAME).includes(TRACKING_COOKIE_AMPLITUDE);
    if (allowAmplitudeTracking) {
        const amplitude = await import('@amplitude/analytics-browser');
        // Initialize Amplitude
        amplitude.init(AMPLITUDE_API_KEY, {
            autocapture: {
                attribution: false,
                elementInteractions: true, // Visual Labeling
                fileDownloads: false,
                formInteractions: true,
                pageViews: true,
                sessions: true, // Session Replay
            },
        });
        // Create and Install Session Replay Plugin
        const { sessionReplayPlugin } = await import('@amplitude/plugin-session-replay-browser');
        // sampleRate of 1 captures 100% of all sessions - not advisable for production environment
        // This parameter is set from the remote configuration available in the Amplitude dashboard
        const sessionReplayTracking = sessionReplayPlugin();
        amplitude.add(sessionReplayTracking);
    }
};

export const track = async ({ name, params }: EventProps): Promise<void> => {
    // we check the initialization of amplitude as the logging can happen too early for the pageview
    if (allowAmplitudeTracking) {
        const amplitude = await import('@amplitude/analytics-browser');
        amplitude.track(name, params);
    }
};

export const setUserProperties = async (
    userId: string | undefined,
    props: UserProperties,
): Promise<void> => {
    if (allowAmplitudeTracking) {
        const amplitude = await import('@amplitude/analytics-browser');
        if (userId) {
            amplitude.setUserId(userId);
        }
        const identify = new amplitude.Identify();
        Object.entries(props).forEach(([key, value]) => identify.set(key, value));
        amplitude.identify(identify);
    }
};
